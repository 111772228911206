import React, { useState } from 'react'
import ProductPlanCard from './planCard'
import { useNavigate } from 'react-router-dom'
import { COLORS } from '../../../resources/colors-contants'
import { useMyContext } from '../context'
import { jwtDecode } from 'jwt-decode'

const unActiveStyle = { fontWeight: '600', fontSize: '18px', lineHeight: '24px', color: COLORS.product_headings }
const activeStyle = {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '24px',
    color: COLORS.primary_background,
    borderBottom: `2px solid ${COLORS.primary_background}`,
    paddingBottom: '17px'
}


interface MyJwtPayload {
    verification_status: any
}

interface wrapperProp {
    amount?: string
    yearOrMonth?: string
}
const MobileProductPlanCardWrapper: React.FC<wrapperProp> = ({ amount, yearOrMonth }) => {
    const [activePlan, setActivePlan] = useState(1)
    const navigate = useNavigate()
    const { myData, loading } = useMyContext()
    const { headers } = myData
    const [token] = useState(localStorage.getItem('token'))
    let decoded: MyJwtPayload | null = null;
    if (token && token !== '') {
        decoded = jwtDecode<MyJwtPayload>(token);
      }

    const data = [
        {
            id: headers[0].id,
            title: myData ? headers[0].name : 'Basic',
            description: 'Obtain the essentials for daily operations and services, then pay just when you want to switch to a different plan.',
            priceText: `₦${myData ? headers[0].amountYear : ''}.00`,
            backgroundColor: COLORS.white_color,
            perYear: true,
            duration: yearOrMonth === 'yearly' ? 'year' : 'month',
            btnBackgroundColor: COLORS.white_color,
            btnColor: COLORS.primary_background,
            btnText: 'Get Started',
            discount: '',
            cardBorderColor: '1px solid #CFD1D3',
            action: '/download',
            data: [
                { text: 'Enjoy advance level inventory management system' },
                { text: 'Track payment and purchases of unlimited customers.' },
                { text: 'Manage unlimited bank accounts & track bank transactions with ease.' }
            ]
        },
        {   id: headers[1].id,
            title: myData ? headers[1].name : 'Plus',
            description: 'Obtain the essentials for daily operations and services, then pay just when you want to switch to a different plan.',
          priceText:  `${yearOrMonth === 'yearly' ? `₦${myData ? `${new Intl.NumberFormat().format(headers[1]?.amountYear * headers[1]?.discount/100)}` : headers[1]?.amountMonth }` : `${new Intl.NumberFormat().format(headers[1]?.amountMonth)}`}`,
            backgroundColor: COLORS.plan_blue,
            discount: `${yearOrMonth === 'yearly' ? `₦${myData ? `${new Intl.NumberFormat().format(headers[1]?.amountYear)}` : ''}` : ''}`,
            perYear: true,
            duration: yearOrMonth === 'yearly' ? 'year' : 'month',
            btnBackgroundColor: COLORS.primary_background,
            btnColor: COLORS.white_color,
            btnText: 'Choose Plan',
            cardBorderColor: 'none',
            action: `/plans-details/${headers[1].id}/${yearOrMonth}`,
            data: [
                { text: 'Enjoy advance level inventory management system' },
                { text: 'Track payment and purchases of unlimited customers.' },
                { text: 'Manage unlimited bank accounts & track bank transactions with ease.' }
            ]
        },
        {   id: headers[2].id,
            title: myData ? headers[2]?.name : 'Enterprise',
            description:
                'Get the optimal price structure for your typical transaction size, payment volume, and other business requirements by partnering with us.',
            priceText: 'Personalise pricing',
            backgroundColor: COLORS.white_color,
            perYear: false,
            duration: '',
            btnBackgroundColor: COLORS.white_color,
            btnColor: COLORS.primary_background,
            btnText: 'Contact Sales',
            cardBorderColor: '1px solid #CFD1D3',
            discount: '',
            action: '/contact',
            data: [
                { text: 'Contact sales to get your customized pricing that suits your business.' }
                // { text: 'Track payment and purchases of unlimited customers.' },
                // { text: 'Manage unlimited bank accounts & track bank transactions with ease.' }
            ]
        }
    ]
    return (
        <div className="d-block d-md-none">
            <div className="text-center justify-content-center mb-5" style={{ marginTop: '4rem' }}>
                {data.map((val, index) => (
                    <span
                        className={`${index !== 2 ? 'me-5' : ''} `}
                        key={index}
                        onClick={() => setActivePlan(index)}
                        style={index === activePlan ? activeStyle : unActiveStyle}
                    >
                        {val.title}
                    </span>
                ))}

                <hr />
            </div>
            <div className="row ">
                <div className="col col-12 col-md-4 mb-3 mb-md-0">
                    <ProductPlanCard
                        title={data[activePlan].title}
                        description={data[activePlan].description}
                        priceText={data[activePlan].priceText}
                        backgroundColor={data[activePlan].backgroundColor}
                        perYear={data[activePlan].perYear}
                        duration={data[activePlan].duration}
                        btnBackgroundColor={data[activePlan].btnBackgroundColor}
                        btnColor={data[activePlan].btnColor}
                        btnText={data[activePlan].btnText}
                        cardBorderColor={data[activePlan].cardBorderColor}
                        btnAction={() => {
                            if (activePlan === 0) {
                                if (token && decoded?.verification_status === 0) {
                                    navigate(data[activePlan].action)
                                }
                                if (token && decoded?.verification_status === 1) {
                                    navigate(`/verify-phone?plan=${headers[0].id}&duration=${yearOrMonth}`)
                                }
                                if(token === null || token === undefined){
                                    navigate(`/signup?plan=${headers[0].id}&duration=${yearOrMonth}&page=signup`)
                                }
                            } else{
                                navigate(data[activePlan].action)
                            }
                            if (data[activePlan].action === '/download') {
                                navigate(data[activePlan].action)
                            }
                        }}
                        arr={data[activePlan].data}
                        discount={data[activePlan].discount}
                    />
                </div>
            </div>
        </div>
    )
}

export default MobileProductPlanCardWrapper
