import React, { useState } from 'react'

interface SelectDropdownProps {
    handleDropdownSelect: (option: 'Klakpad Sella' | 'Klakpad Otel' | 'Klakpad Menu') => void // Match the type with the parent
    selectedDropdownItem: 'Klakpad Sella' | 'Klakpad Otel' | 'Klakpad Menu' // Add the selectedDropdownItem as a prop
    dropdownList: readonly string[] // Add dropdownList as a prop
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({ handleDropdownSelect, selectedDropdownItem, dropdownList }) => {
    const handleSelect = (option: 'Klakpad Sella' | 'Klakpad Otel' | 'Klakpad Menu') => {
        handleDropdownSelect(option) // Call the passed function with the selected option
    }

    return (
        <div className="">
            <div className="btn-group">
                <button
                    style={{
                        fontSize: 16,
                        fontWeight: 500,
                        zIndex: 999
                    }}
                    className="btn downloads__wrapper--select rounded"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="me-1">
                            <span style={{ marginLeft: -3 }}>{selectedDropdownItem}</span>
                        </span>
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.25" y="0.25" width="24.5" height="24.5" rx="12.25" stroke="#96A5C5" strokeWidth="0.5" />
                            <path d="M8.5 10.5L12.5 14.5L16.5 10.5" stroke="#2C4A8B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                </button>
                <ul
                    className="dropdown-menu ps-0 pb-0 pt-0 mt-2"
                    aria-labelledby="dropdownMenuButton"
                    style={{
                        paddingLeft: '10px',
                        width: '100%',
                        overflowY: 'hidden'
                    }}
                >
                    {dropdownList.map((item) => (
                        <li
                            key={item}
                            onClick={() => handleSelect(item as 'Klakpad Sella' | 'Klakpad Otel' | 'Klakpad Menu')} // Cast to the correct type
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = '#F3F7FA'
                                e.currentTarget.style.borderRadius = '4px 4px 0 0'
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = ''
                            }}
                            style={{
                                cursor: 'pointer',
                                fontSize: 16,
                                lineHeight: '20px',
                                letterSpacing: '-0.12px',
                                padding: '10px'
                            }}
                        >
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default SelectDropdown
