import React from 'react'
import '../styles/HeroSection.css'
import RegularBtn from '../../../components/buttons/RegularBtn'
import { COLORS } from '../../../resources/colors-contants'
import { useNavigate } from 'react-router-dom'
import RegularBtn2 from '../../../components/buttons/RegularBtn2'

const HeroSection: React.FC = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="home d-none d-md-block py-5" data-aos="zoom-out-down" data-aos-offset="300" data-aos-easing="linear">
                <div className="container  ">
                    <div className="row ">
                    <div className="col-md-6   d-none d-md-block    ">
                            <h3 className="home__col--title p-4 mt-5 ">Digitalising business processes to drive sales both offline & online.</h3>
                            <p className="ps-4 pe-5 home__col--text">
                                Unlock Business Potential, Boost Sales, Maximise Profits, Operate Efficiently Both Offline and Online on a Robust Platform
                                Crafted for You.
                            </p>
                            <div className=" mb-3 mb-md-0 p-4 pt-5">
                                <RegularBtn2 btnHeight="48px" borderColor='2px solid #2C4A8B' borderRadius="7px" color="white" backgroundColor="#2C4A8B"  text="Download Klakpad App" btnFontSize='16px' btnFontWeight='500' padding='10px 25px' btnAction={()=>navigate('/download')}/>

                            </div>
                            <div className="mb-5" />
                        </div>
                        <div
                            className="col-md-6 mt-5 d-none d-md-block pt-5"
                            style={{
                                backgroundImage: 'url(/assets/hero.png)',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                objectFit: 'cover',
                                height: '52vh',
                                width: '50%'
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSection
