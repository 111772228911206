import React, { useState } from 'react'

import { MyContextProvider } from '../../../components/newPlans/context'
import ProductPlanCardWrapper from '../../../components/newPlans/components/productPlanCard'
import TogglePlanBtns from './TogglePlanBtns'
import ProductPlanCardWrapper2 from '../../../components/newPlans/components/mobileProductPlanCard2'
import SelectDropdown from '../../../components/dropdown/testDropdown'
// import SelectDropdown from '../../../components/dropdown/selectDropdown'

type PriceHeroProps = {
    amount: number
    setAmount: (value: number) => void
    setYearOrMonth: (value: string) => void
    yearOrMonth: string
    page?: string
}
const PriceHero: React.FC<PriceHeroProps> = ({ amount, setAmount, setYearOrMonth, yearOrMonth, page }) => {
    const [selectedDropdownItem, setSelectedDropdownItem] = useState('Retail & Service')
    const handleDropdownSelect = (option: string) => {
        setSelectedDropdownItem(option); // `option` is a string
      };

    const dropdownList = ['Klakpad Sella', 'Klakpad Otel', 'Klakpad Menu']
    // const handleDropdownSelect = (event: React.ChangeEvent<HTMLSelectElement>) => setSelectedDropdownItem(event.target.value)
    return (
        <div className="my-5 py-md-4 container">
            <div className="pricehero py-5 ">
                <div className="row text-center justify-content-center">
                    <p className="pricehero--title px-5 mb-1  mb-md-5"> { page ? 'Choose a plan that best suit your needs' : 'Select the plan that best suit your needs' }</p>

                    <div className="col-auto downloads__wrapper pb-4">
                        <SelectDropdown handleDropdownSelect={handleDropdownSelect} selectedDropdownItem={"Klakpad Sella"} dropdownList={dropdownList}/>
                    {/* <select className="form-select shadow-none downloads__wrapper--select downloads__wrapper--toggle" onChange={handleDropdownSelect}>
                        <option className="downloads--item" value="Retail & Service">Klakpad Sella</option>
                        <option className="downloads--item" value="Hotel Management & Solution (HMS)">Klakpad Otel</option>
                        <option className="downloads--item" value="Restaurant & Lounge">Klakpad MenuPad</option>
                    </select> */}
                </div>
                   <div className="mt-2">
                   <TogglePlanBtns setAmount={setAmount} setYearOrMonth={setYearOrMonth}/>
                   </div>
                </div>
            </div>
            <div className="row ms-2 me-2" >
                <MyContextProvider>
                    <ProductPlanCardWrapper amount={amount} yearOrMonth={yearOrMonth} cardMarginTop={'0rem'} />
                    <ProductPlanCardWrapper2 amount={amount} yearOrMonth={yearOrMonth} cardMarginTop={'0rem'}/>
                </MyContextProvider>
    
            </div>
        </div>
    )
}
export default PriceHero
