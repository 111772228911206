import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { postRequest } from '../../utility/apiRequest';

const RegistrantTable = () => {
    // const [data, setData] = useState([
    //     {
    //         serial_number: 1,
    //         name: 'Taiwo rhoda',
    //         email: 'taiwo@gmail.com',
    //         phone: '09033884477',
    //         address: 'Ire askari',
    //         date: '2023-09-01'
    //     },
    //     {
    //         serial_number: 1,
    //         name: 'rhoda',
    //         email: 'taiwo@gmail.com',
    //         phone: '09033884477',
    //         address: 'Ire askari',
    //         date: '2023-09-01'
    //     }
    // ])
    const { isLoading, isError, data } = useQuery<any>('result', () => postRequest('registrants'));

    
    return (
        <>
           <div className="container pt-5">
            <div className="row pt-5">
            <table className="table">
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Address</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.data?.data.map((val: any) => (
                        <tr key={val.serial_number}>
                            <td>{val.serial_number}</td>
                            <td>{val.name}</td>
                            <td>{val.email}</td>
                            <td>{val.phone}</td>
                            <td>{val.address}</td>
                            <td>{val.date}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
           </div>
        </>
    )
}
export default RegistrantTable
