import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useMyContext } from '../context'
import { COLORS } from '../../../resources/colors-contants'
// import { COLORS } from '../../resources/colors-contants';

type PriceHeroProps = {
    setAmount: (value: string) => void
    setYearOrMonth: (value: string) => void
  
}
const TogglePlanBtns: React.FC<PriceHeroProps>  = ({ setAmount, setYearOrMonth }) => {
    const [selectedBtn, setSelectedBtn] = useState<any>('yearly')
    const { myData, loading } = useMyContext()
    const { headers } = myData
    console.log(headers, 'toggleBtn')

    const handleButtonClick = (btnType: string) => {
        setSelectedBtn(btnType)
        {
            btnType === 'yearly'
                ? setAmount(new Intl.NumberFormat().format(+myData?.headers[1]?.amountYear * headers[1]?.discount))
                : setAmount(new Intl.NumberFormat().format(myData?.headers[1]?.amountMonth))
        }
        {
            btnType === 'yearly' ? setYearOrMonth('yearly') : setYearOrMonth('month')
        }
    }

    const getButtonStyle = (btnType: string) => {
        return selectedBtn === btnType ? { backgroundColor: COLORS.primary_background, color: 'white' } : { color: COLORS.primary_background }
    }

    return (
        <>
            <div className="d-grid gap-0 d-none d-md-block" style={{ marginTop: '5rem' }}>
                <div className="d-flex mx-auto plans__toggle" style={{ backgroundColor: '#F2F5FA', width: 'fit-content', padding: '3px' }}>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('yearly')}
                        onClick={() => handleButtonClick('yearly')}
                    >
                        <span style={{ fontSize: '16px', fontWeight: '500' }}>Yearly</span>
                    </button>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('monthly')}
                        onClick={() => handleButtonClick('monthly')}
                    >
                        <span style={{ fontSize: '16px', fontWeight: '500' }}>Monthly</span>
                    </button>
                </div>
                <p className="mt-3 text-center">
                    {selectedBtn === 'yearly' ? (
                        <b className="pricehero--boldertext text-center">
                            {' '}
                            {loading ? 'loading' : <i>You Save {myData ? + myData?.headers[1]?.discount : ''}% per year</i>}
                        </b>
                    ) : (
                        ''
                    )}
                </p>
            </div>
            <div className="d-grid gap-0 d-block d-md-none" style={{ marginTop: '4rem' }}>
                <div className="d-flex mx-auto plans__toggle" style={{ backgroundColor: '#F2F5FA', width: 'fit-content', padding: '3px' }}>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('yearly')}
                        onClick={() => handleButtonClick('yearly')}
                    >
                        <span style={{ fontSize: '16px', fontWeight: '500' }}>Yearly</span>
                    </button>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('monthly')}
                        onClick={() => handleButtonClick('monthly')}
                    >
                        <span style={{ fontSize: '16px', fontWeight: '500' }}>Monthly</span>
                    </button>
                </div>
                <p className="mt-3 text-center">
                    {selectedBtn === 'yearly' ? (
                        <b className="pricehero--boldertext">  {loading ? 'loading' : <i>You Save {myData ? + myData?.headers[1]?.discount : ''}% per year</i>}</b>
                    ) : (
                        ''
                    )}
                </p>
            </div>
        </>
    )
}

export default TogglePlanBtns
