import React, { useState } from "react";

const DownloadInfoMobile = () => {
  
    const [tabSwitch, setTabSwitch] = useState<string>('others');
    const handleSwitchTab = (tab: string) => {
        setTabSwitch(tab)
    }
    return(
        <>
              <div className="row justify-content-center px-5">
                <div
                    className="card border-0 d-md-block d-lg-none"
                    style={{
                        width: '100%',
                        marginBottom: 48,
                        paddingTop: '12px',
                        backgroundColor: '#F4F7FE',
                        borderRadius: '8px',
                        fontSize: 16,
                        letterSpacing: -0.16,
                        marginTop: 55
                    }}
                >
                    <div className="d-flex">
                        <div className="">
                            <svg width="20" className="me-2" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_8574_7088)">
                                    <path
                                        d="M10.0001 13.3333V9.99996M10.0001 6.66663H10.0084M18.3334 9.99996C18.3334 14.6023 14.6025 18.3333 10.0001 18.3333C5.39771 18.3333 1.66675 14.6023 1.66675 9.99996C1.66675 5.39759 5.39771 1.66663 10.0001 1.66663C14.6025 1.66663 18.3334 5.39759 18.3334 9.99996Z"
                                        stroke="#2C4A8B"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_8574_7088">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div className="">
                            <p style={{ color: '#111A24' }}>
                                Try out the new Klakpad app beta version below and be among the first to experience the latest features. Your feedback will help
                                us refine the app before the official release.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="row justify-content-center px-5"
                
            >
                <div className="card border-0 bg-transparent d-md-block d-lg-none" style={{width: '100%'}}>
                <div
                    className="d-flex  position-relative mb-4">
                         <span className="position-absolute top-0  translate-middle badge downloads__switch--badge">New <span className="visually-hidden">unread messages</span></span>
                    <button style={{
                    }} 
                    className={`downloads__switch--btn  ${tabSwitch === 'clientServer' ? 'downloads__switch--activeBtn' : ''}`} onClick={() => handleSwitchTab('clientServer')}>
                       <div className="d-flex align-items-center">
                        <span> Client Server version  </span> &nbsp;&nbsp;
                        <svg  width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.0001 13.3334V10.0001M10.0001 6.66675H10.0084M18.3334 10.0001C18.3334 14.6025 14.6025 18.3334 10.0001 18.3334C5.39771 18.3334 1.66675 14.6025 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6025 1.66675 18.3334 5.39771 18.3334 10.0001Z"
                                stroke="#888D92"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                       </div>
                       
                    </button>
                    <button className={`downloads__switch--btn  ${tabSwitch === 'others' ? 'downloads__switch--activeBtn ' : ''}`} onClick={() => handleSwitchTab('others')}  style={{
                    }}>Other versions</button>
                </div>
                </div>
            </div>
        </>
    )
}
export default DownloadInfoMobile;