import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getRequest } from '../../../resources/api-request';
import moment from 'moment';
import { LuDownload } from 'react-icons/lu';
import { NavLink } from 'react-router-dom';
import DownloadInfoMobile from './downloadInfoMobile';

declare global {
    interface Window {
        MSStream: any;
    }
}

interface DeviceOSState {
    osName: 'Android' | 'iOS' | 'Unknown';
}

type VersionType = {
    version: string;
    description: string;
    date: string; // Ensure this is in a format that can be parsed by Date, like "YYYY-MM-DD"
    arch: { size: string; bit: string; link: string }[];
};

const DownloadButton = ({ version }: { version: VersionType }) => (
    <div className="mt-4 border-bottom mb-4 justify-content-center ms-2 ">
        <div className="downloads__table--version">{version.version}</div>
        <p className="downloads__table--description">{version.description}</p>
        <div className="row pb-3">
            <div className="col-10">
                <small className="text-secondary pe-2 downloads__table--text">Date released:</small>
                <span className="downloads__table--text">{moment(version.date).format('MMMM Do, YYYY')}</span>
            </div>
            <div className="col-2 text-end">
                <span className="downloads__table--text">
                    {version.arch[0].size ? version.arch[0].size + 'MB' : version.arch[1].size + 'MB'}
                </span>
            </div>
        </div>
        <div className="dropdown selectdiv fw-medium mb-3 pb-3 align-items-center">
            <button className="" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <LuDownload className="mx-1" /> Download
            </button>
            <ul className="dropdown-menu">
                {version.arch.map((arch: any, index: number) => (
                    <li key={index}>
                        <a className="dropdown-item" href={arch?.link} target="_blank" rel="noreferrer">
                            Download ({arch?.bit})bit
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

const DownloadContent = ({ sortedVersions, activeTab, currentTab }: { sortedVersions: VersionType[], activeTab: number | null, currentTab: number }) => {
    if (activeTab !== currentTab) return null;
    if (sortedVersions.length === 0) return <h5 className='text-center'>No available downloads yet.</h5>;

    return (
        <>
            {sortedVersions.map((version, index) => (
                <DownloadButton key={index} version={version} />
            ))}
        </>
    );
};

const DownloadMobileView = () => {
    const [deviceOS, setDeviceOS] = useState<DeviceOSState>({ osName: 'Unknown' });
    const [activeTab, setActiveTab] = useState<null | number>(null);
    const { isLoading, isError, data } = useQuery<any>('result', () => getRequest('https://api.klakpad.com/download_process.php'));
    const [selectedCategory, setSelectedCategory] = useState<'stand_alone' | 'client_server'>('stand_alone');

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (/android/i.test(userAgent)) {
            setDeviceOS({ osName: 'Android' });
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
            setDeviceOS({ osName: 'iOS' });
        } else {
            setDeviceOS({ osName: 'Unknown' });
        }
    }, []);

    if (isLoading) {
        return (
            <div className="row align-items-center justify-content-center">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (isError) {
        console.log(isError);
        return <div>Encountered an error</div>;
    }

    const sortedVersions = (platform: string, category: 'stand_alone' | 'client_server') => 
        (data?.data?.[category]?.[platform] ?? []).sort((a: VersionType, b: VersionType) => new Date(b.date).getTime() - new Date(a.date).getTime());

    const platforms = [
        { id: 1, name: 'Windows', icon: 'fa-windows' },
        { id: 2, name: 'Mac', icon: 'fa-apple' },
        { id: 3, name: 'Linux', icon: 'fa-linux' },
    ];

    const { osName } = deviceOS;
    return (
        <>
        {/* <DownloadInfoMobile /> */}
        <div className="row d-md-block d-lg-none justify-content-center">
           
            <DownloadInfoMobile />
            <p className="text-center ">Klakpad app is also available on:</p>
            <div className="d-flex align-items-center justify-content-center pb-4">
                {osName === 'Android' && (
                    <NavLink to="https://play.google.com/store/apps/details?id=com.klakpad.klakpad" target='_blank'>
                        <img src="/assets/playstore.svg" className="img-fluid" alt="Play Store" />
                    </NavLink>
                )}
                {osName === 'iOS' && (
                    <NavLink to="https://apps.apple.com/us/app/klakpad-sella/id6504258004" target='_blank'>
                        <img src="/assets/appstore.svg" className="img-fluid" alt="App Store" />
                    </NavLink>
                )}
            </div>
            {platforms.map((platform) => (
                <div className="row" key={platform.id}>
                    <button
                        onClick={() => setActiveTab(activeTab === platform.id ? null : platform.id)}
                        className={`nav-link downloads--link mx-3 ${activeTab === platform.id || activeTab === null ? 'downloads--active' : ''}`}
                    >
                        <i className={`fa-brands ${platform.icon} mx-2`} /> {platform.name}
                    </button>
                    <DownloadContent
                        sortedVersions={sortedVersions(platform.name, selectedCategory)}
                        activeTab={activeTab}
                        currentTab={platform.id}
                    />
                </div>
            ))}
        </div>
        </>
    );
};

export default DownloadMobileView;
