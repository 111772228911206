import React, { useState } from 'react'
import { COLORS } from '../../../resources/colors-contants'
import { useQuery } from 'react-query'
import { getRequest } from '../../../resources/api-request'

const TogglePlanBtns = ({ setAmount, setYearOrMonth }: any) => {
    const { isLoading, isError, data } = useQuery<any>(['result', setAmount, setYearOrMonth], () => getRequest('subscription-plans'))
    const [selectedBtn, setSelectedBtn] = useState<any>('yearly')

    const handleButtonClick = (btnType: string) => {
        setSelectedBtn(btnType)
        {
            btnType === 'yearly'
                ? setAmount(+data?.data?.headers[1]?.amountYear * data?.data?.headers[1].discount)
                : setAmount(data?.data?.headers[1]?.amountMonth)
        }
        {
            btnType === 'yearly' ? setYearOrMonth('yearly') : setYearOrMonth('month')
        }
    }

    const getButtonStyle = (btnType: string) => {
        return selectedBtn === btnType ? { backgroundColor: COLORS.primary_background, color: 'white' } : { color: COLORS.primary_background }
    }

    return (
        <>
            <div className="d-grid gap-0 d-none d-md-block ">
                <div className="d-flex mx-auto plans__toggle" style={{ backgroundColor: '#F2F5FA', width: 'fit-content', padding: '3px' }}>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('yearly')}
                        onClick={() => handleButtonClick('yearly')}
                    >
                        <span style={{ fontSize: '16px', fontWeight: '500' }}>Yearly</span>
                    </button>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('monthly')}
                        onClick={() => handleButtonClick('monthly')}
                    >
                        <span style={{ fontSize: '16px', fontWeight: '500' }}>Monthly</span>
                    </button>
                </div>
                <p className="mt-3">
                    {selectedBtn === 'yearly' ? (
                        <b className="pricehero--boldertext"> {isLoading ? '' : <i>You Save {+data?.data?.headers[1].discount}% per year</i>}</b>
                    ) : (
                        ''
                    )}
                </p>
            </div>
            <div className="d-grid gap-0 d-block d-md-none mt-md-2 mt-sm-4">
                <div className="d-flex mx-auto plans__toggle" style={{ backgroundColor: '#F2F5FA', width: 'fit-content', padding: '3px' }}>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('yearly')}
                        onClick={() => handleButtonClick('yearly')}
                    >
                        <span style={{ fontSize: '14px', fontWeight: '500' }}>Yearly</span>
                    </button>
                    <button
                        className="btn btn-lg px-4 py-1 rounded-pill"
                        type="button"
                        style={getButtonStyle('monthly')}
                        onClick={() => handleButtonClick('monthly')}
                    >
                        <span style={{ fontSize: '14px', fontWeight: '500' }}>Monthly</span>
                    </button>
                </div>
                <p className="mt-3">
                    {selectedBtn === 'yearly' ? (
                        <b className="pricehero--boldertext"> {isLoading ? '' : <i>You Save {+data?.data?.headers[1].discount}% per year</i>}</b>
                    ) : (
                        ''
                    )}
                </p>
            </div>
        </>
    )
}

export default TogglePlanBtns
