import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import '../styles/planCard.css'
import { COLORS } from '../../../resources/colors-contants'
import RegularBtn from '../../buttons/RegularBtn'

interface productPlanCardProp {
    title?: string
    description?: string
    priceText?: any
    subPrice?: string
    arr?: { text: string }[]
    backgroundColor?: string
    perYear?: boolean
    duration?: string
    btnBackgroundColor?: string
    btnColor?: string
    btnText?: string
    cardBorderColor?: string
    discount?: any
    btnAction?: () => void
    isStyled?: boolean
    displayCheckIcon?: boolean
}
const ProductPlanCard: React.FC<productPlanCardProp> = ({
    title,
    description,
    priceText,
    subPrice,
    arr,
    backgroundColor,
    perYear,
    duration,
    discount,
    btnBackgroundColor,
    btnColor,
    btnText,
    cardBorderColor,
    isStyled,
    btnAction
}) => {
    const cardContent = {
        border: cardBorderColor,
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingTop: '40px',
        paddingBottom: '40px',
        borderRadius: '10px',
        backgroundColor: backgroundColor
    }
    return (
        <div className=''>
            <div style={isStyled ? {} : cardContent} className="d-lg-block d-none">
                <h4 id="planCardHead" style={{ fontWeight: '600', marginBottom: '2rem', color: COLORS.product_headings }}>
                    {title}
                </h4>
                <p id="planCardDesc" style={{ fontWeight: '400', letterSpacing: '-2%', color: COLORS.product_paragraph }}>
                    {description}
                </p>
                <div className="mt-5 mb-4 mb-md-5">
                    {perYear ? (
                        <>
                            <div>
                                {discount && (
                                    <s id="discountText" style={{ color: '#888C91', fontWeight: '600' }}>
                                        {discount}
                                    </s>
                                )}
                            </div>
                            <div>
                                <span id="priceText" style={{ fontWeight: '600', letterSpacing: '-2%' }}>
                                    {priceText}
                                </span>
                                <span id="perYear" style={{ color: '#394049', fontWeight: '400' }}>
                                    /{duration}
                                </span>
                            </div>
                        </>
                    ) : (
                        <span id="priceText" style={{ fontWeight: '600', letterSpacing: '-2%' }}>
                            {priceText}
                        </span>
                    )}
                </div>
                {/* <p id="featureHead" style={{ fontWeight: '500' }}>
                    Features Include:
                </p> */}
                {/* {title === 'Plus' ? <><p style={{fontWeight: 500, color: btnBackgroundColor}}>Free on 1st year for one branch only</p></> : ''} */}
                {arr &&
                    arr.map((dat, index) => (
                        <div className="d-flex mb-2" key={index}>
                            {title === "Enterprise" ? '' : <><img src="/assets/check1.svg" alt="" className="me-3" style={{marginTop: '-10px'}}/></>}
                            {/* <FontAwesomeIcon className="me-3 mt-1" icon={faCheck} /> */}
                            <p id="featureHead" style={{ fontWeight: '400' }}>
                                {dat.text}
                            </p>
                        </div>
                    ))}

                {isStyled ? <div style={{ marginTop: '4rem' }}></div> : ''}

                {isStyled ? (
                    <div className="text-center" style={{ position: 'absolute', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}>
                        <RegularBtn
                            btnAction={btnAction}
                            text={btnText}
                            width="270px"
                            backgroundColor={btnBackgroundColor}
                            color={btnColor}
                            regPad="py-2"

                            // padding="10px"rem
                            // borderRadius="10px"
                        />
                    </div>
                ) : (
                    <div className="text-center mt-4 ">
                        <RegularBtn
                            btnAction={btnAction}
                            text={btnText}
                            width="270px"
                            backgroundColor={btnBackgroundColor}
                            color={btnColor}
                            regPad="py-2"
                            btnHeight="48px"
                            // padding="10px"rem
                            // borderRadius="10px"
                        />
                    </div>
                )}
            </div>

            <div style={isStyled ? {} : cardContent} className="d-md-block d-lg-none">
                <h4 id="planCardHead" style={{ fontWeight: '600', marginBottom: '2rem', color: COLORS.product_headings }}>
                    {title}
                </h4>
                <p id="planCardDesc" style={{ fontWeight: '400', letterSpacing: '-2%', color: COLORS.product_paragraph }}>
                    {description}
                </p>
                <div className="mt-5 mb-4 mb-md-5">
                    {perYear ? (
                        <>
                            <div>
                                {discount && (
                                    <s id="discountText" style={{ color: '#394049', fontWeight: '600' }}>
                                        {discount}
                                    </s>
                                )}
                            </div>
                            <div>
                                <span id="priceText" style={{ fontWeight: '600', letterSpacing: '-2%' }}>
                                    {priceText}
                                </span>
                                <span id="perYear" style={{ color: '#394049', fontWeight: '400' }}>
                                    /{duration}
                                </span>
                            </div>
                        </>
                    ) : (
                        <span id="priceText" style={{ fontWeight: '600', letterSpacing: '-2%' }}>
                            {priceText}
                        </span>
                    )}
                </div>
               
                {/* {title === 'Plus' ? <><p style={{fontWeight: 500, color: btnColor}}>Free on 1st year for one branch only</p></> : ''} */}
                {arr &&
                    arr.map((dat, index) => (
                        <div className="d-flex mb-2" key={index}>
                            {title === "Enterprise" ? '' : <><img src="/assets/check1.svg" alt="" className="me-3" style={{marginTop: '-10px'}}/></>}
                            {/* <FontAwesomeIcon className="me-3 mt-1" icon={faCheck} /> */}
                            <p id="featureHead" style={{ fontWeight: '400' }}>
                                {dat.text}
                            </p>
                        </div>
                    ))}

                {isStyled ? <div style={{ marginTop: '4rem' }}></div> : ''}

                {isStyled ? (
                    <div className="text-center" style={{ position: 'absolute', bottom: '40px', left: '50%', transform: 'translateX(-50%)' }}>
                        <RegularBtn
                            btnAction={btnAction}
                            text={btnText}
                            width="270px"
                            backgroundColor={btnBackgroundColor}
                            color={btnColor}
                            regPad="py-2"

                            // padding="10px"rem
                            // borderRadius="10px"
                        />
                    </div>
                ) : (
                    <div className="text-center mt-4 ">
                        <RegularBtn
                            btnAction={btnAction}
                            text={btnText}
                            width="270px"
                            backgroundColor={btnBackgroundColor}
                            color={btnColor}
                            regPad="py-2"
                            btnHeight="48px"
                            // padding="10px"rem
                            // borderRadius="10px"
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default ProductPlanCard
