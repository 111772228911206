import moment from 'moment'
import React from 'react'
import { LuDownload } from 'react-icons/lu'
type VersionType = {
    version: string
    description: string
    date: string // Ensure this is in a format that can be parsed by Date, like "YYYY-MM-DD"
    arch: { size: string; bit: string; link: string }[]
    beta: string
}

export const DownloadButton = ({ arch }: { arch: { size: string; bit: string; link: string }[] }) => (
    <div className="dropdown mb-3 selectdiv  text-center">
        <button
            className="d-flex align-items-center w-100 btn btn-primary"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ borderRadius: '10px', padding: '10px 15px' }}
        >
            <div className="d-flex align-items-center justify-content-between w-100">
               
                <div className="d-flex align-items-center">
                    <LuDownload className="me-2" fontSize={20} />
                    <span>Download</span>
                </div>

               
                <div style={{ borderLeft: '.5px solid #fff', height: '47px', marginLeft: '15px', marginRight: '10px' }}></div>

             
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="white" strokeWidth="2.25" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        </button>

    
        <ul className="dropdown-menu">
            {arch.map((archItem, index) => (
                <li key={index}>
                    <a className="dropdown-item" href={archItem.link} target="_blank" rel="noreferrer">
                        Download ({archItem.bit})bit
                    </a>
                </li>
            ))}
        </ul>
    </div>
)
export const VersionTable = ({ versions }: { versions: VersionType[] }) => (
    <>
        {versions.length === 0 ? (
            <h3 className="mt-5 text-center">No downloads available yet</h3>
        ) : (
            <table className="table text-start downloads__table">
                <thead>
                    <tr>
                        <th className="downloads__table--th1" style={{ position: 'relative', left: '3px' }} scope="col">
                            Version
                        </th>
                        <th className="downloads__table--th2" scope="col" style={{ textAlign: 'left' }}>
                            Size
                        </th>
                        <th className="downloads__table--th3" scope="col">
                            Date released
                        </th>
                        <th style={{ width: '4%' }} className="downloads__table--th" />
                    </tr>
                </thead>
                <tbody>
                    {versions.map((version, index) => (
                        <tr key={index} className="justify-content-center align-items-center">
                            <td className="downloads__table--td ">
                                <div className="d-flex flex-column align-items-flex" style={{
                                    marginTop: 12,
                                    // marginBottom:6
                                }}>
                                    <div className="d-flex align-item-flex">
                                        <span className="downloads__table--version pt-2">Klakpad Sella  {version.version}</span>
                                        {version.beta === '1' ? <div className="downloads__table--badge">BETA version</div> : ''}
                                    </div>
                                    <div className="downloads__table--description pb-2">{version.description}</div>
                                </div>
                            </td>
                            <td className="">
                                <div className="d-flex flex-column text-start" style={{
                                    paddingTop: 8
                                }}>
                                    <div className="downloads__table--version ">&nbsp;</div>
                                    <div className=" downloads__table--text me-5">
                                        {version.arch[0].size ? `${version.arch[0].size}MB` : `${version.arch[1].size}MB`}
                                    </div>
                                </div>
                            </td>
                            <td className="py-2" style={{ position: 'relative', left: '20px' }}>
                                <div className="d-flex flex-column justify-content-center" style={{
                                    paddingTop: 8
                                }}>
                                    <div className="downloads__table--version ">&nbsp;</div>
                                    <div className=" downloads__table--text ms-4">{moment(version.date).format('MMMM Do, YYYY')}</div>
                                </div>
                            </td>
                            <td className="py-0 position-relative" style={{ position: 'relative', right: '3%' }}>
                                <div className="d-flex flex-column" style={{
                                    marginTop: 15,
                                    // marginBottom: 5
                                }}>
                                    <DownloadButton arch={version.arch} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )}
    </>
)
export default VersionTable
